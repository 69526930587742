import React from 'react';
import { IonButton, IonButtons, IonTitle } from '@ionic/react';
import styled from 'styled-components';
import { User } from 'firebase/auth';
import { getMode } from '@ionic/core';
import Avatar from '../Avatar';

interface UserProfileBarProps {
  user: User;
  isAdmin: boolean;
}

const Name = styled<{ $isIOS: boolean } & any>(IonTitle as any)`
  ${(props) =>
    props.$isIOS &&
    `
    padding-left: 48px;
    padding-right: 48px;
  `}
`;
const AdminText = styled(IonTitle)`
  font-size: 10px;
  color: var(--ion-color-secondary);
`;

const UserProfileBar: React.FC<UserProfileBarProps> = ({ user, isAdmin }) => {
  const isIOS = getMode() === 'ios';

  return (
    <>
      <Name $isIOS={isIOS}>{user.displayName}</Name>
      {isAdmin && <AdminText>Admin</AdminText>}
      <IonButtons slot="end">
        <IonButton routerLink="/account">
          <Avatar
            photoUrl={user.photoURL!}
            userIdentifier={user.uid!}
            title={user.displayName!}
          />
        </IonButton>
      </IonButtons>
    </>
  );
};

export default UserProfileBar;
