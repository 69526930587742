import {
  child,
  Database,
  DataSnapshot,
  equalTo,
  getDatabase,
  limitToFirst,
  onChildAdded,
  onChildChanged,
  onValue,
  orderByChild,
  push,
  query,
  ref,
  set,
  startAt,
  startAfter,
  update as firebaseUpdate,
  QueryConstraint,
  limitToLast,
  orderByKey,
  endAt,
  endBefore,
  orderByValue,
  remove,
} from 'firebase/database';
import React, { useContext } from 'react';
import { LOADING_STATE } from '../../../util/loading';
import { useFirebase } from '../../firebase';
import CaseContext from './CaseContext';
import { Message } from './messages';

export enum AllowedSpecies {
  DOG = 'dog',
  CAT = 'cat',
  HORSE = 'horse',
}

export const SpeciesMap = {
  [AllowedSpecies.DOG]: 'Dog',
  [AllowedSpecies.CAT]: 'Cat',
  [AllowedSpecies.HORSE]: 'Horse',
};

export enum Sex {
  MALE = 'male',
  FEMALE = 'female',
}

export enum CaseStatus {
  OPEN = 'open',
  SIGNED_OFF = 'signed_off',
  CLOSED = 'closed',
}

export enum LastMessageStatus {
  UNREAD = 'unread',
  READ = 'read',
}

export interface Site {
  location: string;
  description: string;
  additionalInfo?: string;
  images: string[];
}

export interface Case {
  id: string;
  caseNumber?: number;
  uid: string;
  user: {
    displayName: string;
    email: string;
  };
  acceptedBy?: {
    uid: string;
    displayName: string;
    signature: string;
    photoUrl: string;
  };
  createdAt: number;

  patient: {
    first: string;
    last: string;
  };

  submitter: {
    first: string;
    last: string;
    // Reports will be emailed to this email address
    email?: string;
  };

  species: AllowedSpecies;

  age: number;
  ageMonths?: number;

  sex: Sex;

  fixed: boolean;

  sites: Site[];

  description: string;

  status: CaseStatus;

  lastModified: number;

  lastMessage?: Message;

  lastMessageStatus?: LastMessageStatus;

  conversation?: {
    [uid: string]: boolean;
  };

  isArchived?: boolean;

  viewedAt?: number;

  billToParentAccount?: boolean;

  invoiceAccount?: {
    uid: string;
    displayName: string;
    email: string;
  };

  parentAccountChargeId?: string /* pendingCharges/{parentUid}/items/{parentAccountChargeId} */;

  // paymentStatus: PaymentStatus; // TODO???
}

const create = (database: Database, newCase: Omit<Case, 'id'>) => {
  const { uid } = newCase;
  return new Promise<Case>((resolve, reject) => {
    const caseKey = push(ref(database, 'cases')).key;
    // TODO update data in multiple places
    const data = {
      [`cases/${caseKey}`]: newCase,
      [`casesByUser/${uid}/${caseKey}`]: true,
      [`openCases/${caseKey}`]: true,
    };

    firebaseUpdate(ref(database), data)
      .then(() => {
        resolve({
          id: caseKey!,
          ...newCase,
        });
      })
      .catch(reject);
  });
};

const update = (
  database: Database,
  caseId: string,
  updatedCase: Omit<Case, 'id' | 'createdAt'>
) => {
  const { uid } = updatedCase;
  return new Promise<Partial<Case>>((resolve, reject) => {
    const dataToUpdate: any = {};
    Object.keys(updatedCase).forEach((key) => {
      if ((updatedCase as any)[key] !== undefined) {
        dataToUpdate[key] = (updatedCase as any)[key];
      }
    });
    // TODO update data in multiple places
    const data = {
      [`cases/${caseId}`]: dataToUpdate,
      [`cases/${caseId}/lastModified`]: Date.now(),
      [`casesByUser/${uid}/${caseId}`]: true,
      [`openCases/${caseId}`]: updatedCase.status === CaseStatus.OPEN,
    };

    firebaseUpdate(ref(database), data)
      .then((snap) => {
        resolve({
          id: caseId!,
          ...updatedCase,
        });
      })
      .catch(reject);
  });
};

const acceptCase = (
  database: Database,
  caseId: string,
  acceptedBy: Case['acceptedBy']
) => {
  const r = child(ref(database, `cases/${caseId}/`), 'acceptedBy');
  return set(r, acceptedBy);
};

const unclaimCase = (database: Database, caseId: string) => {
  const r = ref(database, `cases/${caseId}/acceptedBy`);
  return remove(r);
};

const getById = (database: Database, caseId: string): Promise<Case> => {
  const caseRef = ref(database, `cases/${caseId}`);
  const getRef = query(caseRef, orderByChild('createdAt'));
  return new Promise((resolve, reject) => {
    onValue(
      getRef,
      (snap) => {
        if (!snap.exists()) {
          return Promise.reject(`Case ${caseId} does not exist`);
        }
        resolve(toCase(snap));
      },
      (err) => {
        console.error(err); // TODO
        reject(err);
      },
      {
        onlyOnce: true,
      }
    );
  });
};

/**
 * Takes a snapshot that contains a list of case IDs and fetches each Case individually
 * Helper function for retrieving cases in `casesByUser`
 */
const getCasesBySnapshotList = (
  database: Database,
  snapshot: DataSnapshot
): Promise<Case[]> => {
  if (!snapshot.exists()) {
    return Promise.resolve([]);
  }
  const caseIds: string[] = [];
  snapshot.forEach((snap) => {
    if (snap.val()) {
      caseIds.push(snap.key!);
    }
  });

  const getAllCases = caseIds.map(async (id) => {
    try {
      return await getById(database, id);
    } catch (e) {
      console.error(`Error retrieving case ${id}`, e);
      return null;
    }
  });
  const promises = Promise.allSettled(getAllCases).then((cases) => {
    const mapped = cases
      .filter(isFullfilled)
      .map((c) => c.value)
      .filter(isNotNull);
    return mapped;
  });
  return promises;
};

export const getCasesWithUnreadMessages = (
  database: Database
): Promise<Case[]> => {
  const casesRef = ref(database, `cases`);
  const getRef = query(
    casesRef,
    orderByChild('lastMessageStatus'),
    equalTo('unread')
  );
  return new Promise((resolve, reject) => {
    onValue(
      getRef,
      async (snapshot) => {
        try {
          const cases = await getCasesBySnapshotList(database, snapshot);
          resolve(cases);
        } catch (e) {
          console.error(e);
          reject(e);
        }
      },
      (err) => {
        console.error(err);
        reject(err);
      },
      {
        onlyOnce: true,
      }
    );
  });
};

export const getCaseIdsByUser = (
  database: Database,
  uid: string
): Promise<string[]> => {
  const casesRef = ref(database, `casesByUser/${uid}`);

  const getRef = query(casesRef);
  return new Promise((resolve, reject) => {
    onValue(
      getRef,
      (snapshot) => {
        if (!snapshot.exists()) {
          return [];
        }
        const caseIds: string[] = [];
        snapshot.forEach((snap) => {
          if (snap.val()) {
            caseIds.push(snap.key!);
          }
        });
        resolve(caseIds);
      },
      (err) => {
        console.error(err);
        reject(err);
      },
      {
        onlyOnce: true,
      }
    );
  });
};

/**
 * TODO - replace this with `getByUser`
 * Need to index cases under `openCasesByUser` and `closedCasesByUser` and migrate existing cases
 * to those indices before this can work
 */
export const getByUser_Deprecated = (
  database: Database,
  uid: string
): Promise<Case[]> => {
  const casesRef = ref(database, `casesByUser/${uid}`);
  const getRef = query(casesRef, orderByChild('createdAt'));
  return new Promise(async (resolve, reject) => {
    onValue(
      getRef,
      (snapshot) => {
        if (!snapshot.exists()) {
          return resolve([]);
        }
        const caseIds: string[] = [];
        snapshot.forEach((snap) => {
          if (snap.val()) {
            caseIds.push(snap.key!);
          }
        });

        const getAllCases = caseIds.map(async (id) => {
          try {
            return await getById(database, id);
          } catch (e) {
            console.error(`Error retrieving case ${id}`, e);
            return null;
          }
        });
        const promises = Promise.allSettled(getAllCases).then((cases) => {
          const mapped = cases
            .filter(isFullfilled)
            .map((c) => c.value)
            .filter(isNotNull);
          return mapped;
        });
        resolve(promises);
      },
      (err) => {
        console.error(err);
        reject(err);
      },
      {
        onlyOnce: true,
      }
    );
  });
};

const getByUser = (
  database: Database,
  uid: string,
  pageSize?: number,
  status?: CaseStatus,
  startAfterCase?: Case
): Promise<Case[]> => {
  const refString =
    status === CaseStatus.OPEN
      ? 'openCasesByUser'
      : status === CaseStatus.CLOSED
      ? 'closedCasesByUser'
      : 'casesByUser';
  const casesRef = ref(database, `${refString}/${uid}`);
  const constraints = [
    orderByKey(),
    pageSize ? limitToLast(pageSize) : undefined,
    startAfterCase ? endBefore(startAfterCase.id || '') : undefined,
  ].filter(Boolean) as QueryConstraint[];

  const getRef = query(casesRef, ...constraints);
  return new Promise(async (resolve, reject) => {
    onValue(
      getRef,
      async (snapshot) => {
        try {
          const cases = await getCasesBySnapshotList(database, snapshot);
          resolve(cases);
        } catch (e) {
          console.error(e);
          reject(e);
        }
      },
      (err) => {
        console.error(err);
        reject(err);
      },
      {
        onlyOnce: true,
      }
    );
  });
};

const isNotNull = <T>(val: T | null): val is T => val !== null;

const isFullfilled = <T>(
  p: PromiseSettledResult<T>
): p is PromiseFulfilledResult<T> => p.status === 'fulfilled';

const updateStatus = (
  database: Database,
  caseId: string,
  userId: string,
  status: CaseStatus,
  isArchived?: boolean
): Promise<void> => {
  const isOpen = status === CaseStatus.OPEN && !isArchived;

  const data = {
    [`cases/${caseId}/status`]: status,
    [`cases/${caseId}/isArchived`]: isArchived,
    [`cases/${caseId}/lastModified`]: Date.now(),
    [`openCases/${caseId}`]: isOpen,
    [`${isOpen ? 'openCasesByUser' : 'closedCasesByUser'}/${userId}/${caseId}`]:
      isOpen,
  };
  return Promise.all([
    firebaseUpdate(ref(database), data),
    isOpen
      ? remove(ref(database, `closedCasesByUser/${userId}/${caseId}`))
      : remove(ref(database, `openCasesByUser/${userId}/${caseId}`)),
  ]).then(() => Promise.resolve());
};

export const getLatestCase = (database: Database) => {
  const casesRef = ref(database, 'cases');
  const constraints = [orderByKey(), limitToLast(1)].filter(
    Boolean
  ) as QueryConstraint[];
  const getRef = query(casesRef, ...constraints);
  return new Promise<Case | null>((resolve, reject) => {
    onChildAdded(
      getRef,
      (snapshot) => {
        if (!snapshot.exists()) {
          return resolve(null);
        }
        resolve(toCase(snapshot));
      },
      (err) => {
        console.error(err);
        reject(err);
      },
      { onlyOnce: true }
    );
  });
};

export const getCases = (
  database: Database,
  constraints: QueryConstraint[] = []
) => {
  const casesRef = ref(database, 'cases');
  const getRef = query(casesRef, ...constraints);

  return new Promise<Case[]>((resolve, reject) => {
    onValue(
      getRef,
      (snapshot) => {
        if (!snapshot.exists()) {
          return resolve([]);
        }

        const cases: Case[] = [];
        snapshot.forEach((snap) => {
          cases.push(toCase(snap));
        });
        const transformedCases = cases.map(transformCase);
        resolve(transformedCases);
      },
      (err) => {
        console.error(err);
        reject(err);
      },
      {
        onlyOnce: true,
      }
    );
  });
};

const getByStatus = (
  database: Database,
  status: CaseStatus,
  pageSize = 25,
  startAfterCase?: Case
) => {
  const casesRef = ref(database, 'cases');
  const constraints = [
    orderByChild('status'),

    startAfterCase ? endBefore(status, startAfterCase.id) : equalTo(status),
    limitToLast(pageSize),
  ].filter(Boolean) as QueryConstraint[];
  const getRef = query(casesRef, ...constraints);
  return new Promise<Case[]>((resolve, reject) => {
    onValue(
      getRef,
      (snapshot) => {
        if (!snapshot.exists()) {
          return resolve([]);
        }

        const cases: Case[] = [];
        snapshot.forEach((snap) => {
          cases.push(toCase(snap));
        });
        const transformedCases = cases.map(transformCase);
        resolve(transformedCases);
      },
      (err) => {
        console.error(err);
        reject(err);
      },
      {
        onlyOnce: true,
      }
    );
  });
};

const markConversationAs = (
  database: Database,
  caseId: string,
  userId: string,
  status: LastMessageStatus
) => {
  const data = {
    [`cases/${caseId}/lastMessageStatus`]: status,
    [`unreadCasesByUser/${userId}/${caseId}`]:
      status === LastMessageStatus.UNREAD,
  };
  return firebaseUpdate(ref(database), data);
};

export interface Invoice {
  paymentMethodId: string;
  products: any[];
  setupIntentId: string;
  stripeCustomerId: string;
}

const getInvoice = (database: Database, caseId: string): Promise<Invoice> => {
  return new Promise((resolve, reject) => {
    onValue(
      ref(database, `/invoices/${caseId}`),
      (snap) => resolve(snap.val() as any),
      (e) => {
        console.error(e);
        reject(e);
      },
      { onlyOnce: true }
    );
  });
};

const markAsViewed = (database: Database, caseId: string) => {
  return set(ref(database, `cases/${caseId}/viewedAt`), Date.now());
};

/**
 * Utility function to transform the raw case values to make sure
 * everything is in the proper format
 * @param rawValues Raw RDB JSON
 * @returns Properly-formatted case
 */
const transformCase = (rawValues: any): Case => {
  return {
    id: rawValues.id,
    uid: rawValues.uid,
    caseNumber: rawValues.caseNumber,
    acceptedBy: rawValues.acceptedBy,
    user: {
      displayName: rawValues.user?.displayName || '',
      email: rawValues.user?.email || '',
    },
    patient: {
      first: rawValues.patient?.first || '',
      last: rawValues.patient?.last || '',
    },
    submitter: {
      first: rawValues.submitter?.first || '',
      last: rawValues.submitter?.last || '',
      email: rawValues.submitter?.email || '',
    },
    species: rawValues.species,
    age: rawValues.age,
    ageMonths: rawValues.ageMonths,
    sex: rawValues.sex,
    fixed: rawValues.fixed === true,
    sites: getSites(rawValues),
    description: rawValues.description || '',
    lastModified: rawValues.lastModified || Date.now(),
    status: rawValues.status || CaseStatus.OPEN,
    createdAt: rawValues.createdAt || Date.now(),
    lastMessage: rawValues.lastMessage,
    lastMessageStatus: rawValues.lastMessageStatus,
    isArchived: rawValues.isArchived || false,
    viewedAt: rawValues.viewedAt,
    parentAccountChargeId: rawValues.parentAccountChargeId || undefined,
    billToParentAccount: rawValues.billToParentAccount || false,
    invoiceAccount: rawValues.invoiceAccount
      ? {
          uid: rawValues.invoiceAccount.uid,
          displayName: rawValues.invoiceAccount.displayName,
          email: rawValues.invoiceAccount.email,
        }
      : undefined,
  };
};

const getSites = (rawValues: any): Site[] => {
  if (!rawValues.sites) {
    return [
      {
        location: rawValues.location || '',
        description: rawValues.description || '',
        images: rawValues.images || [],
        additionalInfo: rawValues.additionalInfo,
      },
    ];
  }

  return rawValues.sites.map((site: any) => ({
    location: site.location || '',
    description: site.description || '',
    images: getImages(site),
    additionalInfo: site.additionalInfo,
  }));
};

const getImages = (rawSite: any): string[] => {
  const nonNullImages = rawSite.images || [];
  return Array.isArray(nonNullImages)
    ? nonNullImages.filter(Boolean)
    : Object.entries(rawSite.images)
        .map((entry) => entry[1])
        .filter(Boolean);
};

export const toCase = (snapshot: DataSnapshot) => ({
  ...transformCase(snapshot.val()),
  id: snapshot.key!,
});

function useCase(caseId?: string) {
  const { app } = useFirebase();
  const { cases, cacheKey } = useContext(CaseContext);
  const findExistingCase = () => cases.find((c) => c.id === caseId);
  const [state, setState] = React.useState<{
    case?: Case;
    cacheKey?: string;
    loadingStatus: LOADING_STATE;
    err?: string | Error;
  }>(() => {
    if (!caseId) {
      return {
        loadingStatus: LOADING_STATE.IDLE,
      };
    }

    const c = findExistingCase();
    return {
      case: c,
      cacheKey,
      loadingStatus: c ? LOADING_STATE.LOADED : LOADING_STATE.LOADING,
    };
  });

  // TODO need to store result in CaseContext so we don't
  // have to keep making network calls
  React.useEffect(() => {
    if (!caseId || !app) {
      return setState({
        case: undefined,
        cacheKey,
        loadingStatus: LOADING_STATE.IDLE,
      });
    }

    const database = getDatabase(app);
    const existingCase = findExistingCase();
    // If cacheKey mismatch we need to store updated case details
    // This assumes the case details in the CaseContext are up-to-date
    const foundCase =
      state.case?.id === caseId && state.cacheKey === cacheKey
        ? state.case
        : existingCase;
    if (foundCase) {
      if (foundCase !== state.case) {
        setState({
          case: foundCase,
          cacheKey,
          loadingStatus: LOADING_STATE.LOADED,
        });
      }
    } else {
      setState((state) => ({
        ...state,
        loadingStatus: LOADING_STATE.LOADING,
        cacheKey,
      }));
      getById(database, caseId)
        .then((c) => {
          setState({
            case: c,
            cacheKey,
            loadingStatus: LOADING_STATE.LOADED,
          });
        })
        .catch((e) => {
          setState({ err: e, loadingStatus: LOADING_STATE.ERROR });
        });
    }
  }, [caseId, cases, state.case, app, cacheKey]);

  return state;
}

function useCaseUpdates(onChange: (c: Case) => void) {
  const { app } = useFirebase();
  React.useEffect(() => {
    const database = getDatabase(app);
    const unsub = onChildChanged(ref(database, 'cases'), (snapshot, b) => {
      console.log('case changed', snapshot.key);
      if (snapshot.exists()) {
        onChange(toCase(snapshot));
      }
    });
    return () => unsub();
  }, [onChange, app]);
}

function useNewCases(isAdmin: boolean, onAdd: (c: Case) => void) {
  const { app } = useFirebase();
  React.useEffect(() => {
    if (!isAdmin) {
      return;
    }

    const database = getDatabase(app);
    const unsub = onChildAdded(ref(database, 'cases'), (snapshot, b) => {
      // console.log('case added', snapshot.key);
      if (snapshot.exists()) {
        onAdd(toCase(snapshot));
      }
    });
    return () => unsub();
  }, [isAdmin, onAdd, app]);
}

export {
  create,
  update,
  acceptCase,
  getById,
  getByUser,
  getByStatus,
  transformCase,
  useCase,
  markConversationAs,
  updateStatus,
  useNewCases,
  useCaseUpdates,
  getInvoice,
  markAsViewed,
  unclaimCase,
};
