import React, { useState } from 'react';
import { Case } from './cases';
import * as RDBCases from './cases';
import * as A from 'fp-ts/Array';
import { LOADING_STATE } from '../../../util/loading';
import { Report } from './reports';
import { Predicate } from 'fp-ts/lib/Predicate';
import { useFirebase } from '../../firebase';
import { getDatabase } from 'firebase/database';

export interface PageArgs {
  pageSize?: number;
  startAfter?: Case;
}
interface ICaseContext {
  cacheKey: string;
  cases: Case[];
  reports: StringMap<Report>;
  setCases: React.Dispatch<React.SetStateAction<Case[]>>;
  setReports: React.Dispatch<React.SetStateAction<StringMap<Report>>>;
  loadingStatus: LOADING_STATE;
  hasRetrievedAllCases: boolean;
  setHasRetrievedAllCases: React.Dispatch<React.SetStateAction<boolean>>;

  adminGetUnreadCases: () => Promise<Case[]>;
  adminGetCasesPaginated: (
    caseStatus: RDBCases.CaseStatus,
    pageArgs: PageArgs,
    invertStatus?: boolean
  ) => Promise<Case[]>;

  getCasesPaginated: (
    userId: string,
    caseStatus: RDBCases.CaseStatus,
    pageArgs: PageArgs
  ) => Promise<Case[]>;
  getUserCases: () => Promise<Case[]>;
  // NOTE: this does not upload to RDB, only adds locally
  // For use after a new case is created to add to local cache
  addCase: (c: Case) => void;
  updateCase: (c: Case) => void;
  getById: (caseId: string) => Promise<Case>;
}

const CaseContext = React.createContext<ICaseContext>(undefined as any);

interface CaseProviderProps {
  //
}

const DEFAULT_PAGE_SIZE = 25;

const matchesId =
  (id: string): Predicate<Case> =>
  (c: Case) =>
    c.id === id;
const findCase = (caseId: string, existingCases: Case[]) =>
  A.findFirst(matchesId(caseId))(existingCases);

export const CaseProvider: React.FC<CaseProviderProps> = ({ children }) => {
  const [loadingStatus, setLoadingStatus] = useState(LOADING_STATE.LOADING);
  const [cases, setCases] = useState<Case[]>([]);
  const [cacheKey, setCacheKey] = useState<string>(Date.now().toString());
  const [hasRetrievedAllCases, setHasRetrievedAllCases] = React.useState(false);
  const [reports, setReports] = useState<StringMap<Report>>({});
  const { currentUser, app } = useFirebase();
  const database = getDatabase(app);

  // Clear hasRetrievedAllCases when user changes
  React.useEffect(() => {
    setHasRetrievedAllCases(false);
  }, [currentUser]);

  const onCaseUpdated = React.useCallback((c: Case) => {
    setCases((existingCases) => {
      if (findCase(c.id, existingCases)) {
        const filteredCases = existingCases.filter(({ id }) => id !== c.id);
        return filteredCases.concat(c);
      }
      return existingCases;
    });
    setCacheKey(Date.now().toString());
  }, []);

  // useCaseUpdates only works for admins since it listens to all cases
  // and only admins have RDB permissions for that
  RDBCases.useCaseUpdates(onCaseUpdated);

  const getUserCases = React.useCallback(() => {
    return new Promise<Case[]>((resolve, reject) => {
      if (currentUser?.uid) {
        RDBCases.getByUser(database, currentUser?.uid!)
          .then((cases) => {
            console.log({ cases });
            resolve(cases);
          })
          .catch(reject);
      } else {
        reject('No user ID');
      }
    });
  }, [currentUser?.uid, database]);

  const adminGetUnreadCases = React.useCallback(() => {
    return RDBCases.getCasesWithUnreadMessages(database);
  }, [database]);

  const adminGetCasesPaginated = React.useCallback(
    (caseStatus: RDBCases.CaseStatus, pageArgs: PageArgs) => {
      return RDBCases.getByStatus(
        database,
        caseStatus,
        pageArgs.pageSize || DEFAULT_PAGE_SIZE,
        pageArgs.startAfter
      ).then((cases) => {
        console.log({ cases });
        return cases;
      });
    },
    [database]
  );

  const getCasesPaginated = React.useCallback(
    (uid: string, caseStatus: RDBCases.CaseStatus, pageArgs: PageArgs) => {
      return RDBCases.getByUser(
        database,
        uid,
        pageArgs.pageSize,
        caseStatus,
        pageArgs.startAfter
      ).then((cases) => {
        console.log({ cases });
        return cases;
      });
    },
    [database]
  );

  const getById = React.useCallback(
    (caseId: string) => {
      return RDBCases.getById(database, caseId).then((foundCase) => {
        // TODO do I need this???
        if (!cases.find((c) => c.id === foundCase.id)) {
          setCases(cases.concat(foundCase));
          setCacheKey(Date.now().toString());
        }
        return foundCase;
      });
    },
    [database]
  );

  const addCase = React.useCallback((c: Case) => {
    setCases((currentCases) => currentCases.concat(c));
    setCacheKey(Date.now().toString());
  }, []);

  const updateCase = React.useCallback((updatedCase: Case) => {
    setCases((currentCases) =>
      currentCases.map((c) => (c.id === updatedCase.id ? updatedCase : c))
    );
    setCacheKey(Date.now().toString());
  }, []);

  return (
    <CaseContext.Provider
      value={{
        cacheKey,
        cases,
        hasRetrievedAllCases,
        setHasRetrievedAllCases,
        reports,
        setReports,
        setCases,
        getById,
        addCase,
        updateCase,
        getUserCases,
        loadingStatus,
        getCasesPaginated,
        adminGetCasesPaginated,
        adminGetUnreadCases,
      }}
    >
      {children}
    </CaseContext.Provider>
  );
};

export default CaseContext;
