import React from 'react';
import InfoSection from '../caseInfo/InfoSection';
import Typography from '../../common/Typography';

interface PathologistInfoProps {
  name: string;
}

const PathologistInfo: React.FC<PathologistInfoProps> = ({ name }) => {
  return (
    <InfoSection title="Pathologist">
      <Typography fontSize="14px">{name}</Typography>
    </InfoSection>
  );
};

export default PathologistInfo;
